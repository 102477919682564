.splitSection {
  display: flex;
  flex-direction: row;
  background: linear-gradient(
    135deg,
    #bde7fb,
    #62c0ea,
    #22a4de,
    #4fbbcd,
    #00a7ca
  );
}

.imageContainer {
  flex: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  flex: 1;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-align: left;
}

.content h2 {
  font-size: 2.9rem;
  line-height: 1.25;
  text-shadow: var(--box-shadow-md);
}

.content p {
  text-shadow: var(--box-shadow-md);
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  line-height: 1.6;
}

.ctaButton {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #00a7ca;
  color: white;
  border: none;
  border-radius: 12px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  max-width: 50%;
}
.ctaButton:hover {
  background-color: var(--primary-dark-blue);
}

@media (max-width: 1023px) {
  .splitSection {
    flex-direction: column;
  }

  .imageContainer {
    width: 100%;
    height: auto;
  }

  .content {
    padding: 2rem 1rem;
    text-align: center;
  }

  .content h2 {
    font-size: 2rem;
  }

  .content p {
    font-size: 1rem;
    text-align: center;
  }

  .ctaButton {
    max-width: 100%;
  }
}
