.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

iframe {
  border: none; /* Removes frame border */
}
