/* Company Info Section */
.companyInfoSection {
  padding: 40px 20px;
  background-color: var(--neutral-light);
  text-align: center;
}

/* Title Styling */
.companyInfoSection h2 {
  position: relative;
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 1px;
}
.companyInfoSection h2::after {
  content: "";
  width: 120px;
  height: 5px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

/* Info Wrapper */
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 0 20px; /* Add padding to center content */
}

/* Individual Info Item (Card) */
.infoItem {
  background-color: var(--neutral-white);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-width: 280px;
  min-height: 400px;
  max-width: 400px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align map at the bottom */
}

/* Spacing between text elements */
.infoItem p {
  margin: 10px 0;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  color: var(--neutral-dark);
  line-height: 1.8;
  text-align: left;
  word-wrap: break-word;
}
.locationAddress {
  text-transform: uppercase;
}

/* Ensure hidden fields (empty) do not break layout */
.infoItem p:empty {
  display: none; /* Hide empty paragraphs */
}

/* Header Styling */
.infoItem h4 {
  font-size: var(--font-size-lg);
  margin-bottom: 10px;
  color: var(--primary-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: left;
}

/* Icon Styling */
.infoItem h4 svg {
  margin-right: 10px;
  font-size: 22px;
}

/* Icon Styling for Paragraphs */
.infoItem p svg {
  margin-right: 10px; /* Add a gap between the icon and text */
  font-size: 18px; /* Adjust the size if needed */
  vertical-align: middle; /* Ensures the icon aligns well with the text */
}

/* Map Styling */
.mapContainer {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  flex-grow: 1; /* Ensure the map scales appropriately */
  min-height: 250px;
}

/* Hover effect on the cards */
.infoItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 500px) {
  .infoItem {
    width: 280px;
    padding: var(--spacer-sm);
  }
  /* Info Wrapper */
  .infoWrapper {
    gap: 20px;
    padding: 0; /* Add padding to center content */
  }
}

@media (max-width: 768px) {
  .infoWrapper {
    flex-direction: column;
    align-items: center;
  }

  .infoItem {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .infoWrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Increase gap for better spacing on larger screens */
  }

  .infoItem {
    flex: 1 1 calc(33.333% - 40px); /* 3 items per row */
    min-width: 500px;
    max-width: 50%; /* Keep max-width for readability */
    height: 100%;
    max-height: 800px;
    text-align: center;
  }
}
