/* src/components/PaymentMethods/PaymentMethods.module.css */

.paymentMethods {
  margin-top: var(--spacer-sm); /* Adds spacing above the section */
  text-align: center;
}

.paymentTitle {
  font-size: var(--h4-font-size); /* Use custom font size */
  font-weight: var(--font-weight-semibold); /* Use custom font weight */
  color: var(--dark-color); /* Set text color */
  margin-bottom: var(--spacer-lg); /* Adds spacing below the title */
}

.paymentLogos {
  display: flex;
  justify-content: center;
  gap: var(--spacer-sm); /* Increased spacing between logos */
  flex-wrap: wrap; /* Ensures that logos wrap on smaller screens */
}

.paymentLogo {
  width: 100px; /* Default width for other logos */
  height: auto;
  transition: var(--transition-base); /* Smooth transition for hover effects */
}

.paymentLogo:hover {
  transform: scale(1.1); /* Slightly increase the size of the logo on hover */
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .paymentLogo {
    width: 100px; /* Smaller width on mobile for better fit */
  }
  .paymentLogos {
    gap: var(--spacer-lg); /* Increased spacing between logos */
  }
}
