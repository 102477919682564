.list {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(280px, 1fr)
  ); /* Adjust min width for smaller screens */
  gap: 20px;
  padding: 20px;
  justify-items: center;
  max-width: 1200px; /* Set a maximum width */
  margin: 0 auto; /* Center the content */
}

@media (min-width: 1536px) {
  .list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    max-width: 1440px; /* Increase max-width for larger screens */
  }
}
