.faqContainer {
  max-width: var(--container-width-lg);
  margin: 0 auto;
  padding: var(--spacer-lg);
  border-radius: var(--border-radius-lg);
}

.faqItem {
  border-bottom: var(--border-width) solid var(--secondary-light-grey);
  padding: var(--spacer-md) 0;
  transition: var(--transition-base);
}

.question {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
  color: var(--primary-dark-blue);
  cursor: pointer;
  transition: color var(--transition-base);
}

.question:hover {
  color: var(--primary-color);
}

.answer {
  margin-top: var(--spacer-sm);
  font-size: var(--font-size-sm);
  color: var(--secondary-dark-grey);
  line-height: var(--line-height-base);
}

.answer-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-base) ease-in-out;
}

.answer-enter-active {
  max-height: 300px; /* Adjust as needed */
}

.answer-leave {
  max-height: 300px;
  overflow: hidden;
  transition: max-height var(--transition-base) ease-in-out;
}

.answer-leave-active {
  max-height: 0;
}
