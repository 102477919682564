.certificatesSection {
  padding: var(--spacer-xl) var(--spacer-lg);
  color: var(--primary-color);
  max-width: var(--container-width-lg);
  margin: 0 auto;
  text-align: center;
}

.title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-bold);
  color: #d4af37; /* Gold color */
  margin-bottom: var(--spacer-xl);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Description Styling */
.description {
  font-size: var(--font-size-md);
  color: var(--neutral-dark);
  max-width: 800px;
  margin: 0 auto var(--spacer-xl);
  line-height: 1.8;
  word-wrap: break-word;
  hyphens: auto;
  font-family: var(--font-family-sans-serif);
}

/* Big Certificate Section */
.bigCertificate {
}

.bigCertificateImage {
  width: 100%;
  max-width: 500px;
}

.bigCertificateTitle {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  font-family: "Playfair Display", serif;
  color: var(--primary-color);
}

.bigCertificateDescription {
  font-size: var(--font-size-md);
  margin-bottom: var(--spacer-md);
  color: var(--neutral-dark);
  font-family: var(--font-family-sans-serif);
  line-height: 1.8;
  text-align: center;
}

/* Scroller Section for Small Certificates */
.certificateScroller {
  overflow-x: auto; /* Allow horizontal scroll */
  overflow-y: hidden; /* Prevent vertical scroll */
  white-space: nowrap; /* Prevent wrapping for manual scrolling */
  -webkit-overflow-scrolling: touch; /* Enables momentum scrolling on iOS */
}

.scrollerContent {
  display: flex;
  gap: var(--spacer-md);
  justify-content: center;
}

.certificateItem {
  padding: var(--spacer-sm);
  border-radius: var(--border-radius-base);
  text-align: center;
  flex: 0 0 auto;
  width: 140px;
  height: 100px; /* Uniform height for all certificates */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: transparent;
}

.certificateImage {
  width: 100%;
  height: 100%; /* Make the image take full height */
  object-fit: contain; /* Ensure the image fits within bounds */
  margin-bottom: var(--spacer-sm);
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .bigCertificateImage {
    max-width: 100%;
  }

  .scrollerContent {
    display: flex;
    gap: var(--spacer-2xs);
  }
  .certificateTitle {
    font-size: var(--font-size-sm);
  }

  .certificateDescription {
    font-size: var(--font-size-sm);
    line-height: 1.5;
  }

  .certificateItem {
    width: 120px;
    height: 100px; /* Ensure uniform height on mobile too */
  }

  .certificateImage {
    height: 100%; /* Maintain image height proportional to certificate item */
  }
}
