.productGridSection {
  padding: var(--spacer-xl) var(--spacer-lg);
  max-width: 1080px;
  margin: 0 auto;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacer-md);
  border: none;
}

/* Updated to ensure productItem is a clickable block */
.productItemLink {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
  display: block; /* Ensure it behaves as a block element */
}

.productItem {
  padding: var(--spacer-md);
  border-radius: var(--border-radius-base);
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: transparent;
  transition: var(--transition-base); /* Smooth transition on hover */
  cursor: pointer; /* Change to pointer to indicate clickability */
  height: 350px;
  max-height: 350px;
  border: none;
  box-shadow: var(--box-shadow-sm);
}

.productItem:hover {
  box-shadow: var(--box-shadow-md);
  transform: var(--hover-transform); /* Apply slight scale effect */
}

.productImage {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: var(--border-radius-sm);
  margin: 0;
}

.productName {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  margin: 0;
  color: var(--secondary-color);
}

.productPrice {
  font-size: var(--font-size-md);
  text-align: center;
}

/* Remove the button styles, as the entire card is now clickable */
.productItem::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(var(--accent-peach-rgb), 0.2);
  border-radius: var(--border-radius-base);
  pointer-events: none; /* Ensure the border does not interfere with clicks */
}
