/* Section container */
.sectionContainer {
  position: relative;
}

.desktopBackground {
  grid-area: image;
  display: block;
  width: 100%;
  height: 650px;
  z-index: 0;
  position: absolute;
  background-image: var(--desktop-bg-image);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Mobile image container */
.mobileImageContainer {
  display: none;
}

/* Content container */
.contentContainer {
  position: relative;
  z-index: 30;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  height: 650px;
}

/* Grid container */
.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
  align-items: center;
  width: 100%;
}

/* Reverse content placement */
.gridContainer.reverse {
  grid-template-areas: "content image";
}

.gridContainer:not(.reverse) {
  grid-template-areas: "image content";
}

/* Text content */
.textContent {
  grid-area: content;
  padding: 3rem 0;
}

/* Title */
.title h2 {
  color: white;
  margin-bottom: 1.25rem;
  letter-spacing: -0.02rem;
  line-height: 1.25;
  text-shadow: var(--box-shadow-md);
}

/* Description */
.description p {
  color: white;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  line-height: 1.6;
  text-shadow: var(--box-shadow-md);
}

/* Call to Actions */
.ctaContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: left;
  width: 100%;
}

/* Button common styles */
.ctaButton {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-family: "Frutiger-Bold";
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: var(--box-shadow-md);
  width: 50%;
  justify-content: space-between;
}

/* Secondary button */
.ctaSecondary {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.ctaSecondary:hover,
.ctaSecondary:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.ctaSecondary:active {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Primary button */
.ctaPrimary {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
}

.ctaPrimary:hover,
.ctaPrimary:focus,
.ctaPrimary:active {
  background-color: var(--primary-dark-blue);
  border: 1px solid var(--primary-color);
}

/* Icon spacing */
.iconSpacing {
  margin-left: 0.5rem;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 550px) {
  .ctaContainer {
    display: grid;
    text-align: center;
  }

  .ctaButton {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 1023px) {
  .desktopBackground {
    display: none;
  }

  .mobileImageContainer {
    display: block;
  }

  .mobileImageContent {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .gridContainer {
    grid-template-columns: 1fr;
    gap: 0rem;
  }

  .textContent {
    padding: 0;
  }

  .contentContainer {
    background-color: #00a7ca;
  }

  .title h2 {
    margin-bottom: 1.25rem;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 2.75rem;
    letter-spacing: -1px;
  }

  .description p {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }

  .ctaButton {
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
  }
  /* Primary button */
  .ctaPrimary {
    background-color: var(--primary-blue);
    color: white;
    border: 1px solid var(--primary-blue);
  }
}

@media (min-width: 1024px) {
  .contentContainer {
    max-width: 1600px;
  }
  .title h2 {
    font-size: 3.4rem;
    line-height: 1.25;
  }

  .description p {
    font-size: 1.25rem;
    margin: 2rem 0;
  }

  .ctaButton {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
}
