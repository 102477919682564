.contactSection {
  display: flex;
  flex-direction: column;
  gap: 40px; /* Slightly reduced gap */
  padding: 60px 20px;
  align-items: center;
}

/* Mobile layout adjustments */
@media (max-width: 768px) {
  .contactSection {
    flex-direction: column;
  }

  .contactForm,
  .companyInfoSection {
    width: 100%; /* Ensure full width on mobile */
    max-width: 100%; /* Prevent too much stretching */
    padding: 0 20px;
  }
}

@media (min-width: 1249px) {
  .contactForm {
    flex: 1; /* Form takes 1 portion of the row */
    max-width: 500px; /* Restrict form width */
  }

  .companyInfoSection {
    flex: 1.5; /* Company info takes a bit more space */
    max-width: 700px; /* Max width for larger screens */
  }
}
