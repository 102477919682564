.QRCodeTemplateSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background-color: var(--neutral-light, #f9f9f9);
  max-width: 800px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  color: #333;
}

.description {
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  text-align: center;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 100%;
  word-break: break-word;
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.copyButton {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
}

.copyButton:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.copyButton:active {
  background-color: #004494;
  transform: scale(0.98);
}
