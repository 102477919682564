/* Call to Action Section */
.ctaSection {
  padding: 80px 20px;
  background: linear-gradient(
    135deg,
    var(--primary-color) 30%,
    var(--accent-color) 100%
  ); /* Add a gradient background */
  color: var(--neutral-white);
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Subtle background pattern for visual interest */
.ctaSection::before {
  content: "";
  position: absolute;
  top: -50px;
  left: -50px;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  z-index: 0;
  pointer-events: none;
}

/* Call to Action Text */
.ctaSection p {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1; /* Ensure text stays above the background pattern */
  position: relative;
}

/* CTA Button */
.ctaButton {
  display: inline-block;
  background-color: var(--neutral-white);
  color: var(--primary-color);
  padding: 15px 40px;
  font-size: 18px;
  font-weight: 600;
  border: 2px solid var(--neutral-white); /* Border for added contrast */
  border-radius: 50px; /* Rounded button for a modern feel */
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

/* Hover Effects for CTA Button */
.ctaButton:hover {
  background-color: var(--primary-color);
  color: var(--neutral-white);
  transform: scale(1.1); /* Smooth scaling on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ctaSection p {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .ctaButton {
    padding: 12px 30px;
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .ctaSection p {
    font-size: 20px;
  }

  .ctaButton {
    padding: 10px 25px;
    font-size: 14px;
  }
}
