.gridItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: var(--light-color);
  border-radius: 10px;
  box-shadow: var(--box-shadow-lg);
  overflow: hidden;
  text-decoration: none;
  transition: background-color 0.3s ease;
  z-index: 999;
  height: 150px;
}

.gridItem:hover {
  background-color: var(--info-light-blue);
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 150px; /* Increased height for better visuals */
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content {
  padding: 15px; /* Adjusted for more padding */
  display: flex;
  align-items: center;
}

.title {
  font-size: 12px; /* Slightly larger */
  font-family: "Frutiger-Black";
  color: var(--secondary-dark-grey);
  text-transform: uppercase;
  margin: 0;
  line-height: 1.8;
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
}
.title:hover {
  color: var(--primary-color);
}
