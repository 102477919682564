.fullwidth {
  width: 100vw;
}

.minHeight {
  width: 100%;
}

.menuContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 73px;
  width: 100%;
  padding: 16px 20px;
  display: grid;
  grid-template-columns: 150px calc(100% - 150px);
  background-color: var(--neutral-white);
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.logoLink {
  position: relative;
  color: black;
  text-decoration: none;
  top: -3px;
  max-width: 120px;
}

.logoImage {
  height: 45px;
}

.iconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.iconContainer svg {
  color: #333;
}

.iconItem {
  margin-left: 0;
}

.hamburger {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transition: transform 0.8s ease-in-out;
}

.hamburger svg {
  transition: transform 0.8s ease-in-out, opacity 0.8s ease;
  color: black;
}

.hamburger:active {
  transform: scale(1.2);
}

.hamburger svg {
  width: 24px;
  height: 24px;
}

/* Mobile Nav List */

.mobileNav {
  position: fixed;
  width: 100%;
  height: calc(100vh - 72px);
  z-index: 99;
  background-color: var(--neutral-white);
  top: 72px;
  left: 0;
  border-top: 1px solid #e0e0e0;
  transition: all 0.3s ease-in-out;
  padding: 0px 0px 120px;
}

.navWrapper {
  width: 100%;
  height: calc(100vh - 72px);
  overflow-y: auto;
  padding: 0px 0px 160px;
}

.navList {
  list-style: none;
  padding: 0 24px;
  margin: 0;
  font-family: "Frutiger";
}

.menuItem {
  border-bottom: 1px solid #e0e0e0;
}

.menuLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  font-family: "Frutiger-Bold";
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 20px 24px 20px 12px;
}

.menuLink:hover {
  color: var(--primary-blue);
}

.arrowIcon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.rotated {
  transform: rotate(180deg);
}

.submenu {
  list-style: none;
  padding-left: 0px;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.submenu.visible {
  max-height: 1200px;
}

.submenuItem {
  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
}

.submenuItem:last-child {
  border-bottom: none;
}

.submenuLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  font-family: "Frutiger-Bold";
  background-color: transparent;
  padding: 20px 24px 20px 12px;
}

.fixedMenuItems {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  z-index: 9999;
}

.fixedMenuList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fixedMenuList li {
  padding: 0px 24px;
  border: none;
}

.menuGray {
  background-color: var(--secondary-light-grey);
}

.menuBlue {
  background-color: var(--primary-blue);
}

.menuBlue a {
  color: white;
}
