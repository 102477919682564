.milestone {
  display: flex;
  margin: 60px 0;
  align-items: center;
  position: relative;
}

/* Aligning milestones based on screen size */
.rightAligned {
  flex-direction: row-reverse;
  text-align: left;
}

.leftAligned {
  flex-direction: row;
  text-align: right;
}

/* Styling the container for the media */
.mediaContainer {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediaContainer img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling the text container */
.textContainer {
  flex: 1;
  padding: 20px 30px;
  max-width: 500px;
}

.textContainer h3 {
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 15px;
  font-weight: bold;
}

.textContainer p {
  font-size: 16px;
  color: var(--neutral-dark);
  line-height: 1.8;
  text-align: left;
  word-wrap: break-word;
}

/* Adding a timeline line for visual alignment */
.milestone::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  background-color: var(--primary-color);
  transform: translateX(-50%);
}

/* Adjust text container margin to create space from the timeline */
.leftAligned .textContainer {
  margin-left: 40px; /* Add space between text and the timeline */
}

.rightAligned .textContainer {
  margin-right: 40px; /* Add space between text and the timeline */
}

/* Mobile Responsive Styling */
@media (max-width: 500px) {
  .mediaContainer {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .milestone {
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0;
  }

  .rightAligned,
  .leftAligned {
    flex-direction: column;
    text-align: left;
  }

  .textContainer {
    padding: 20px;
    max-width: 100%;
    margin-top: 20px;
  }

  .textContainer p {
    hyphens: auto;
  }

  .milestone::before {
    display: none;
  }

  /* Separate styling for mediaContainer with iframe (video) */
  .mediaContainer.hasVideo {
    width: 85vw;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
  }

  .mediaContainer.hasVideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Styling for images */
  .mediaContainer.hasImage {
    width: 85vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mediaContainer.hasImage img {
    width: 100%;
    max-width: 400px;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .leftAligned .textContainer {
    margin-left: 0;
  }

  .rightAligned .textContainer {
    margin-right: 0;
  }
}
