/* DesktopNav.module.css */

/* General container and layout */
.header {
  width: 100%;
}

.container {
  min-height: 146px;
}

.innerContainer {
  padding-top: 1rem;
  background-color: var(--light-color);
  width: 100%;
}

.grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
}

/* Logo styles */
.logoLink {
  display: flex;
  align-items: center;
}

.logoImage {
  width: 170px;
  height: auto;
}

/* Top right styles */
.topRight {
  display: flex;
  align-items: center;
}

.teamLink {
  font-size: 14px;
  font-family: "Frutiger-Bold";
  text-transform: uppercase;
  color: var(--neutral-dark-grey);
  margin-right: 1rem;
  text-decoration: none;
}

/* CTA buttons */
.ctas {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.primaryCta,
.secondaryCta {
  padding: 0.75rem 1rem;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-family: "Frutiger-Bold";
  text-transform: uppercase;
  border-radius: 999px;
  text-decoration: none;
  border: 2px solid var(--primary-color);
}

.primaryCta {
  background-color: var(--primary-color);
  color: var(--light-color);
}

.primaryCta:hover {
  background-color: var(--primary-dark-blue);
  border-color: var(--primary-dark-blue);
}

.secondaryCta {
  background-color: transparent;
  color: var(--neutral-dark-grey);
}

.secondaryCta:hover {
  background-color: var(--primary-color);
  color: var(--light-color);
  border-color: var(--primary-color);
}

.ctaIcon {
  margin-left: 1rem;
}

/* Account and Cart styles */
.accountLink {
  font-size: 1.6rem;
  margin-left: 1rem;
}

.cartButton {
  position: relative;
  margin-left: 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.cartIcon {
  font-size: 1.6rem;
}

.cartCount {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: #f00;
  color: #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 16px;
}

/* Navigation styles */
.navMenu {
  width: 100%;
  background-color: var(--light-color);
}

.navList {
  display: flex;
  justify-content: flex-end;
  gap: 1rem 1rem;
  margin: 0;
  padding: 0 3rem;
  list-style: none;
}

.navItem {
  position: relative;
}

.navLink {
  font-size: 14px;
  font-family: "Frutiger-Bold";
  text-transform: uppercase;
  color: var(--neutral-dark-grey);
  text-decoration: none;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  transition: color 0.3s ease;
}

.navLink:hover {
  color: #004c71;
}

/* Base Dropdown Style */
.navDropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--light-color);
  padding: 20px;
  width: 100%; /* Default width for regular dropdowns */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

/* Hover to Show Dropdown */
.navItem:hover .navDropdown {
  display: block;
}

.fullWidthDropdown {
  position: absolute;
  top: 145px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: var(--light-color);
  padding: 27px;
  box-shadow: var(--box-shadow-lg);
}
/* Grid Layout for Full-Width Dropdown */
.dropdownGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 0 auto;
}
.dropdownLink {
  text-decoration: none;
  color: #000;
}

.dropdownItem {
  padding: 0.75rem 0;
}

.dropdownLink:hover {
  color: var(--primary-color);
}

@media (max-width: 1210px) {
  .fullWidthDropdown {
    padding: 16px;
  }
}
