:root {
  /* ===========================
      COLOR PALETTE
   ============================ */

  /* Primary Colors */
  --primary-color: #00a7ca;
  --primary-light-blue: #6dcfdf;
  --primary-blue: #22a4de;
  --primary-dark-blue: #0056b3;

  /* Secondary Colors */
  --secondary-color: #6c757d;
  --secondary-light-grey: #d6d8db;
  --secondary-grey: #6c757d;
  --secondary-dark-grey: #343a40;

  /* Accent Colors */
  --accent-color: #ff6347; /* Tomato color for highlighting */
  --accent-peach: #f7d1cd;
  --accent-light-pink: #d1b3c4;
  --accent-purple: #b392ac;
  --accent-peach-rgb: 255, 204, 203;

  /* Placeholder Colors */
  --placeholder-color: rgba(var(--accent-peach-rgb), 0.6);

  /* Neutral Colors */
  --neutral-white: #ffffff;
  --neutral-dark-grey: #333132;

  /* Status Colors */
  --success-color: #28a745;
  --success-light-green: #5cb85c;
  --success-dark-green: #1e7e34;

  --danger-color: #dc3545;
  --danger-light-red: #f8d7da;
  --danger-dark-red: #bd2130;

  --warning-color: #ffc107;
  --warning-light-yellow: #fff3cd;
  --warning-dark-yellow: #856404;

  --info-color: #17a2b8;
  --info-light-blue: #d1ecf1;
  --info-dark-blue: #0c5460;

  --light-color: #f8f9fa;
  --dark-color: #343a40;

  /* ===========================
      TYPOGRAPHY
   ============================ */
  --font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;

  /* Typography Scale */
  --font-size-3xs: 0.2rem; /* ~12px */
  --font-size-2xs: 0.7rem; /* ~12px */
  --font-size-xs: 0.8rem; /* ~12px */
  --font-size-sm: 1rem; /* 16px (base) */
  --font-size-md-sm: 1.125rem; /* 18px */
  --font-size-md: 1.25rem; /* ~20px */
  --font-size-lg: 1.563rem; /* ~25px */
  --font-size-xl: 1.953rem; /* ~31px */
  --font-size-2xl: 2.25rem; /* ~36px */
  --font-size-xxl: 2.441rem; /* ~39px */

  /* Headings */
  --h1-font-size: var(--font-size-2xl);
  --h2-font-size: var(--font-size-xl);
  --h3-font-size: var(--font-size-lg);
  --h4-font-size: var(--font-size-md);
  --h5-font-size: var(--font-size-sm);
  --h6-font-size: var(--font-size-xs);

  /* Font Weights */
  --font-weight-normal: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* ===========================
      SPACING
   ============================ */
  --spacer-3xs: 0.25rem; /* 4px */
  --spacer-2xs: 0.5rem; /* 8px */
  --spacer-xs: 0.625rem; /* 10px */
  --spacer-sm: 1.25rem; /* 20px */
  --spacer-md: 1.5rem; /* 24px */
  --spacer-lg: 2rem; /* 32px */
  --spacer-xl: 2.5rem; /* 40px */
  --spacer-2xl: 3rem; /* 48px */
  --spacer-3xl: 3.75rem; /* 60px */
  --spacer-4xl: 5rem; /* 80px */

  /* ===========================
      BORDERS
   ============================ */
  --border-radius-sm: 2px;
  --border-radius-base: 4px;
  --border-radius-md: 5px;
  --border-radius-lg: 8px;
  --border-width: 1px;
  --border-color: #dee2e6;

  /* ===========================
      SHADOWS
   ============================ */
  --box-shadow-2xs: 0 1px 1px rgba(0, 0, 0, 0.005);
  --box-shadow-xs: 0 2px 2px rgba(0, 0, 0, 0.01);
  --box-shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --box-shadow-md: 0 4px 8px rgba(0, 0, 0, 0.3);
  --box-shadow-base: 0 4px 8px rgba(0, 0, 0, 0.9);
  --box-shadow-lg: 0 6px 12px rgba(0, 0, 0, 0.15);
  --box-shadow-hover: 0 6px 12px rgba(0, 0, 0, 0.15);
  --box-shadow-sm-login: 0 0 3px var(--accent-peach);
  --box-shadow-opacity: 0.2;
  --box-shadow-opacity-hover: 0.5;

  /* ===========================
      TRANSITIONS
   ============================ */
  --transition-slow: 0.5s ease;
  --transition-base: 0.3s ease;
  --transition-fast: 0.2s ease;

  /* ===========================
      Z-INDEX
   ============================ */
  --z-index-modal: 1050;
  --z-index-dropdown: 1000;
  --z-index-navbar: 1030;
  --z-index-tooltip: 1070;

  /* ===========================
      GRADIENTS
   ============================ */
  --gradient-primary: linear-gradient(
    45deg,
    var(--primary-light-blue),
    var(--primary-blue)
  );
  --gradient-secondary: linear-gradient(
    45deg,
    var(--secondary-light-grey),
    var(--secondary-grey)
  );

  /* ===========================
      MEDIA QUERIES
   ============================ */
  --breakpoint-xs: 0px;
  --breakpoint-sm: 500px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1140px;
  --breakpoint-xxl: 1200px;

  /* ===========================
      OPACITY
   ============================ */
  --opacity-disabled: 0.65;
  --opacity-hover: 0.8;

  /* ===========================
      LINE HEIGHTS
   ============================ */
  --line-height-sm: 1.25;
  --line-height-base: 1.5;
  --line-height-lg: 1.75;

  /* ===========================
      CONTAINER WIDTHS
   ============================ */
  --container-width-sm: 540px;
  --container-width-md: 720px;
  --container-width-lg: 960px;
  --container-width-xl: 1140px;
  --container-width-xxl: 1200px;

  /* ===========================
      BUTTON STYLING
   ============================ */
  --button-padding: 12px 24px;
  --button-font-size: var(--font-size-sm);
  --button-font-weight: var(--font-weight-bold);
  --button-border-radius: var(--border-radius-lg);
  --button-box-shadow: var(--box-shadow-base);
  --button-disabled-bg: #cccccc;
  --button-disabled-color: #666666;
  --button-hover-transform: translateY(-2px);
  --button-active-transform: scale(0.97) translateY(1px);

  /* ===========================
      SIDEBAR DIMENSIONS
   ============================ */
  --sidebar-width: 5rem;
  --sidebar-height: 5rem;
}

@font-face {
  font-family: "Frutiger-Black";
  src: url("/src/assets/fonts/frutiger/FrutigerLTStd-Black.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Frutiger-Bold";
  src: url("/src/assets/fonts/frutiger/FrutigerLTStd-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Frutiger-Light";
  src: url("/src/assets/fonts/frutiger/FrutigerLTStd-Light.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Crimson";
  src: url("/src/assets/fonts/crimson/CrimsonText-BoldItalic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Additional font-face for other Frutiger or Trajan weights and styles if needed */

/* Repeat for other fonts */

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Frutiger-Light", var(--font-family-base);
  font-size: var(--font-size-base);
  color: var(--dark-color);
  background-color: var(--light-color);
  line-height: var(--line-height-sm); /* Use line-height variable */
  word-wrap: break-word;
  hyphens: none;
  text-align: left;
}

h1,
h2,
h3 {
  font-family: "Frutiger-Black", serif;
  text-transform: uppercase;
  hyphens: none;
}
h1 {
  line-height: 3.75rem;
}

.bold-text {
  font-family: "Frutiger-Bold";
  font-weight: 700; /* Matches the bold variant */
}

.light-text {
  font-family: "Frutiger-Light";
  font-weight: 300; /* Matches the light variant */
}
