/* Carousel.module.css */

.carouselContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0; /* Optional: Adds vertical padding */
}

.carouselItem {
  padding: 0 10px; /* Adds horizontal gaps between slides */
}
