.productHighlights {
  padding: var(--spacer-xl) var(--spacer-lg);
  background-color: var(--light-color);
  max-width: 1200px;
  margin: 0 auto;
}

.productTitle {
  position: relative;
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  color: var(--primary-color);
  margin-bottom: var(--spacer-xl);
  letter-spacing: 1.5px;
  position: relative;
}

/* Product title underline */
.productTitle::after {
  content: "";
  width: 100px; /* Change the width slightly */
  height: 5px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: -10px; /* Adjust the bottom position */
  left: 50%;
  transform: translateX(-50%);
}

/* Mobile: horizontal scroll */
.productGrid {
  display: flex;
  gap: var(--spacer-md);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: var(--spacer-md) 0;
  scrollbar-width: thin; /* Thin scrollbar width */
  scrollbar-color: var(--primary-color) var(--light-color); /* Thumb and track colors for Firefox */
}

/* Show scrollbar explicitly on mobile */
.productGrid::-webkit-scrollbar {
  height: 12px; /* Increase the height for a larger scrollbar */
}

.productGrid::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* Color of scrollbar thumb */
  border-radius: 5px; /* Rounded corners for a smooth appearance */
  border: 3px solid var(--light-color); /* Creates padding inside the thumb for contrast */
}

.productGrid::-webkit-scrollbar-track {
  background-color: var(--primary-color); /* Background of scrollbar track */
  border-radius: 5px; /* Optional: round the edges of the track */
}

.productGrid::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color); /* Change thumb color on hover */
}

.productItem {
  background-color: var(--neutral-white);
  padding: var(--spacer-sm);
  border-radius: var(--border-radius-base);
  text-align: center;
  scroll-snap-align: center;
  min-width: 200px;
  flex-shrink: 0;
  text-decoration: none;
}
.productItem:hover {
  box-shadow: var(--box-shadow-md);
  transform: var(--hover-transform); /* Apply slight scale effect */
}

.productImage {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-bottom: var(--spacer-sm);
}

.productName {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  margin: var(--spacer-xs) 0;
  color: var(--dark-color);
}

.productPrice {
  font-size: var(--font-size-md);
  color: var(--primary-color);
  margin-top: var(--spacer-xs);
  text-align: center;
}

/* Desktop: Grid layout */
@media (min-width: 1024px) {
  .productGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--spacer-lg);
    overflow-x: unset;
  }

  .productItem {
    min-width: auto;
  }
}
