.recruitmentContainer {
  padding: var(--spacer-xl) var(--spacer-sm);
  background-color: var(--light-color);
  max-width: var(--container-width-xxl);
  margin: 0 auto;
}

.heading {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-bold);
  color: var(--primary-dark-blue);
  text-align: center;
  margin-bottom: var(--spacer-md);
}

.description {
  font-size: var(--font-size-md);
  color: var(--secondary-dark-grey);
  line-height: var(--line-height-base);
  text-align: center;
  margin-bottom: var(--spacer-lg);
}

.section {
  margin-bottom: var(--spacer-xxl);
}

.subheading {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-semibold);
  color: var(--primary-color);
  margin-bottom: var(--spacer-sm);
}

.list {
  list-style-type: disc;
  padding-left: var(--spacer-md);
  font-size: var(--font-size-md);
  color: var(--secondary-dark-grey);
  line-height: var(--line-height-lg);
}
