/* Contact Form Section */
.contactFormSection {
  padding: 60px 20px;
  background-color: var(--neutral-light);
  text-align: center;
  min-width: 650px;
}

/* Form styling */
.contactForm {
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Title styling */
.contactFormSection h2 {
  position: relative;
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: 1px;
}
.contactFormSection h2::after {
  content: "";
  width: 120px;
  height: 5px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

/* Input fields and textarea styling */
.contactForm input,
.contactForm textarea {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--neutral-dark);
  font-size: 16px;
}

.contactForm input:focus,
.contactForm textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 167, 202, 0.5);
}

/* Error message styling */
.errorMessage {
  color: var(--danger-color);
  font-size: 0.875rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Highlight input fields with errors */
.errorInput {
  border-color: var(--danger-color);
  background-color: #fff0f0;
}

.contactForm button {
  padding: 15px 20px;
  background-color: var(--primary-color);
  color: var(--neutral-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
}

.contactForm button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .contactForm {
    padding: 20px;
  }

  .contactFormSection {
    padding: 40px 20px;
    min-width: 0px;
  }
}
