/* components/CampaignCard/CampaignCard.module.css */
.card {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 320px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.imageWrapper {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-bottom: 2px solid #007bff;
}

.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.card:hover .imageWrapper img {
  transform: scale(1.1);
}

.content {
  padding: 20px;
}

.content h3 {
  margin: 0 0 10px;
  font-size: 1.5em;
  color: #333;
}

.content p {
  color: #666;
  font-size: 1em;
  line-height: 1.6;
}

.button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: none;
  }
}
