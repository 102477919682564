.featuresSection {
  padding: var(--spacer-4xl) var(--spacer-sm);
  background: linear-gradient(
    135deg,
    #bde7fb,
    #62c0ea,
    #22a4de,
    #4fbbcd,
    #00a7ca
  );
  margin: 0;
  color: var(--neutral-white);
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.featuresTitle {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  color: var(--neutral-white);
  margin-bottom: var(--spacer-4xl);
  position: relative;
  text-shadow: var(--box-shadow-md);
}

.featuresTitle::after {
  content: "";
  width: 120px;
  height: 5px;
  background-color: var(--neutral-white);
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--border-radius-sm);
}

.card {
  position: relative;
  width: 100%;
}

.featureImage {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: var(--border-radius-lg);
}

.featureTitle {
  position: absolute;
  bottom: var(--spacer-sm);
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-size-sm);
  color: var(--neutral-white);
  text-shadow: var(--box-shadow-base);
  text-align: center;
  width: 100%;
  padding: var(--spacer-xs);
  border-radius: var(--border-radius-sm);
}

@media (max-width: 500px) {
  .featuresSection {
    padding: var(--spacer-4xl) var(--spacer-sm);
    background: linear-gradient(135deg, #62c0ea 0%, #6dcfdf 80%);
    margin: 0;
    color: var(--neutral-white);
    position: relative;
    overflow: hidden;
    height: 750px;
  }
  .featuresTitle {
    margin-bottom: var(--spacer-xl);
  }
}
