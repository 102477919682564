.testimonialsSection {
  padding: 60px 20px;
  background-color: var(--neutral-light);
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.title {
  position: relative;
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-semibold);
  text-align: center;
  color: var(--primary-color);
  margin-bottom: var(--spacer-xl);
  letter-spacing: 1.5px;
  position: relative;
}

.title::after {
  content: "";
  width: 120px;
  height: 5px;
  background-color: var(--primary-color);
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.testimonialsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Each Testimonial Item */
.testimonialItem {
  background-color: var(--neutral-white);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 300px; /* Make all cards the same width */
  height: auto; /* Ensure they adapt to content */
  flex: 1; /* Ensure all cards take equal space */
}

/* Avatar Styling */
.testimonialItem img,
.initialsAvatar {
  border-radius: 50%;
  margin-bottom: 15px;
}

/* Testimonial Quote */
.testimonialItem p {
  font-style: italic;
  font-size: 14px;
  color: var(--neutral-dark);
  margin: 0 0 15px;
  padding: 0 10px;
  line-height: 1.8;
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
}

/* Testimonial Name */
.testimonialItem h4 {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
  margin-top: 5px;
}

/* Hover Effect */
.testimonialItem:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .testimonialsWrapper {
    flex-direction: column;
    align-items: center;
  }

  .testimonialItem {
    transform: translateY(0);
    margin-bottom: 30px;
  }
}
