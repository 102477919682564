/* Footer container */
.footer {
  padding: 40px 20px;
  background-color: var(--primary-color);
  color: var(--neutral-white);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

/* Footer Links */
.footer ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  display: flex;
  gap: 20px;
}

.footer ul li a {
  color: var(--neutral-white);
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
  font-family: "Frutiger-Light";
}

/* Hover effect for links */
.footer ul li a:hover {
  color: var(--accent-color);
}

/* Livro de Reclamações Icon */
.livroReclamacoesLink {
  color: var(--neutral-white);
  font-size: 24px; /* Adjust icon size */
  margin-bottom: 15px; /* Add some space between links and social icons */
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effect for the Livro de Reclamações icon */
.livroReclamacoesLink:hover {
  color: var(--accent-color); /* Change color on hover */
  transform: scale(1.1); /* Slight scaling on hover */
}

/* Social Media Icons */
.socialMedia {
  display: flex;
  gap: 20px;
}

.socialMedia a {
  font-size: 24px; /* Ensure icon size is consistent */
  color: var(--neutral-white);
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effect for social icons */
.socialMedia a:hover {
  transform: scale(1.2);
  color: var(--accent-color); /* Slight color shift on hover */
}
.cookiePreferencesLink {
  background: none;
  border: none;
  color: var(--neutral-white);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 0;
  font-family: "Frutiger-Light";
}

.cookiePreferencesLink:hover {
  color: var(--accent-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
  }

  .footer ul {
    flex-direction: column;
    gap: 10px;
  }

  .livroReclamacoesLink {
    font-size: 20px;
  }

  .socialMedia a {
    font-size: var(--font-size-xl);
  }
}
