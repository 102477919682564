/* Mobile First Styling */
.productDetail {
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: column;
  padding: calc(var(--spacer-lg) + 73px) var(--spacer-lg) var(--spacer-lg); /* Added padding-top to account for fixed navbar height */
}

.productDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productDetailImage {
  width: 100%;
  max-width: 300px; /* Limit width for mobile */
  max-height: 300px; /* Set a maximum height to control image height */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the image fits within the specified width and height */
  margin-bottom: var(--spacer-lg); /* Use spacing variable */
}

.productDetailInfo {
  line-height: var(--line-height-lg); /* Use line-height variable */
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
}

.productDetailName {
  color: var(--primary-color);
  font-size: var(--h1-font-size); /* Use heading font size variable */
  margin: 0;
  line-height: var(--line-height-sm); /* Use line-height variable */
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
}

.productDetailPrice {
  color: var(--primary-blue); /* Use the primary blue color */
  font-size: var(--font-size-xl); /* Use font-size variable */
  margin: 0;
}

.productDetailDescription {
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacer-xl); /* Use spacing variable */
  line-height: var(--line-height-lg); /* Use line-height variable */
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
}

.productDetailSpecialDescription {
  font-style: italic;
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 1rem 0;
  color: var(--dark-color);
  line-height: var(--line-height-lg);
  text-align: left;
  word-wrap: break-word;
  hyphens: auto;
}

.buyButton {
  background-color: var(--primary-color);
  color: var(--neutral-white);
  padding: var(--button-padding);
  border: none;
  cursor: pointer;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  width: 100%;
  border-radius: var(--button-border-radius);
  transition: var(--transition-base);
  text-decoration: none;
}

.buyButton:hover {
  background-color: var(--primary-light-blue);
  transform: var(--button-hover-transform);
  text-decoration: none;
}

.productDetailBackLink {
  display: inline-flex;
  align-items: center;
  margin-top: var(--spacer-lg);
  color: var(--dark-color); /* Change text color for contrast */
  border-radius: 10px;
  text-decoration: none;
  font-family: "Frutiger-Black";
  font-size: var(--font-size-xs);
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
}

.productDetailBackLink:hover {
  color: var(--primary-dark-blue); /* Darken background on hover */
}

/* Desktop View Styling */
@media (min-width: 1024px) {
  .productDetail {
    flex-direction: column; /* Make content stacked vertically */
    align-items: center;
    padding: var(--spacer-lg);
  }

  .productDetailContainer {
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px; /* Container width for consistent desktop layout */
  }

  .productDetailImage {
    width: 50%; /* Larger image on desktop */
    max-width: 500px; /* Limit width for large screens */
    max-height: 400px; /* Set a maximum height to control image height */
    margin-bottom: 0;
  }

  .productDetailInfo {
    text-align: left;
    width: 50%;
    padding-left: var(--spacer-lg);
  }
}
