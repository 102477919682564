.termsOfServiceContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  line-height: 1.8;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  border-radius: 8px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

h3 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  text-align: left;
}

@media (max-width: 768px) {
  .termsOfServiceContainer {
    padding: 1.5rem;
  }

  h1 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  p {
    font-size: 0.95rem;
  }
}
