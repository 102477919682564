.languageSelector {
  position: relative;
  margin-left: 1rem;
}

.languageDropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.languageText {
  display: flex; /* Added */
  align-items: center; /* Added */
  font-size: 1.2rem;
  font-family: "Frutiger-Bold";
  text-transform: uppercase;
  color: var(--neutral-dark-grey);
}

.flagIcon {
  width: 24px;
  height: 20px;
  margin-right: 8px;
  object-fit: contain;
}

.languageCode {
  margin-left: 0.25rem;
}

.languageIcon {
  margin-left: 0.5rem;
}

.languageLinks {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90px;
  display: block;
  z-index: 999;
  font-size: 1.2rem;
  font-family: "Frutiger-Bold";
  align-items: center;
}

.languageLinks ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.languageOption {
  display: flex; /* Aligns the flag and text horizontally */
  align-items: center; /* Vertically centers the flag and text */
  padding: 0.8rem; /* Ensures sufficient clickable area */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

.languageOption .flagIcon {
  margin-right: 8px; /* Consistent spacing between flag and text */
}

.languageOption:hover {
  background-color: #f0f0f0; /* Optional: Adds a hover effect for better UX */
}

.languageLinks ul li {
  padding: 0.8rem;
}

.languageLinks ul li a {
  color: var(--neutral-dark-grey);
  text-decoration: none;
}

.languageLinks ul li a:hover {
  color: var(--primary-color);
}
